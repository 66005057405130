<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Choices from "choices.js";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import AgentAutoComplete from '@/components/agent-auto-complete-input'
import AddressAutoComplete from '@/components/address-auto-complete-input'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import CurrencyInput from '@/components/currency-input'

import {
  required,email
} from "vuelidate/lib/validators";

import { search_lawyer,postal_code_query } from '@/api/misc'
import { getDealBasicApi } from '@/api/deal/basic'

import {financial} from '@/api/misc'
//import CurrencyInput from '@/components/currency-input'


/**
 * Setup PM  component
 */
export default {
  page: {
    title: "Deal",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  validations() {

    if (this.deal.deal_business != 'P' && this.deal.deal_business != 'H') {
      if (this.deal.deal_type == 'L') {
        return {
          deal: {
           
            selling_price: { required },
            acceptance_date: { required },
            contract_date: { required },
            deposit_due_date: { required },
            deposit: { required },
            listing_commission: {required},
            selling_commission: {required},
            listing_commission_gst: {required},
            selling_commission_gst: {required},
            buyers_sellers : {
              $each: {
                email: {
                  email
                }
               
              }
            }

          
          },
        }
      } else {

        return {
          deal: {
           
            selling_price: { required },
            acceptance_date: { required },
            contract_date: { required },
            deposit_due_date: { required },
            deposit: { required },
            listing_commission: {},
            selling_commission: {},
            listing_commission_gst: {},
            selling_commission_gst: {},
          },
        }
      }
    }  else {
      return {
        deal: {
         
          selling_price: {  },
          acceptance_date: {  },
          contract_date: {  },
          deposit_due_date: {  },
          deposit: {  },
          listing_commission: {},
          selling_commission: {},
          listing_commission_gst: {},
          selling_commission_gst: {},
        },
      }
    }
  },


  methods: {


    onPriceChanged(evt) {
      console.log(evt)
    },
    formSubmit() {
      console.log(this.deal)

      this.gst_required = false
      let commission_required = false

      this.deal.agents.map(e =>{
        if (!e.commission) {
          commission_required = true
        }
        if (!e.gst) {
          this.gst_required = true
        }

      })

      this.deal.agents.map(e =>{
        if (!e.commission) {
          commission_required = true
        }
        if (!e.gst) {
          this.gst_required = true
        }
      })

      this.deal.other_brokers.map(e => {
        if ((e.first_name == '' || typeof e.first_name == 'undefined') && (e.agent_name == '' || typeof e.agent_name == 'undefined')) {
         
          this.other_broker_name_required = true
          e.agent_name_required = true
          return
        } else {
          e.agent_name_required = false
        }

        let full_name =  e.agent_name.split(' ')
        e.first_name = full_name[0]
        e.last_name = full_name.length > 1?full_name[1] :full_name[0]

      })



      
      

      this.$v.$touch();

      if (this.$v.$invalid == false && this.gst_required == false && commission_required == false) {


        //

        if (this.deal.deal_type == 'L') {
          let commission_validate =  financial((Number(this.deal.listing_commission_gst) + Number(this.deal.listing_commission)) , 2)
          let agent_commission    = financial(this.deal.agents.reduce((a, c) => a += (Number(c.commission) + Number(c.gst)), 0), 2)
          console.log(commission_validate, '==', agent_commission)
          if (commission_validate != agent_commission) {
            this.$alertify.error(`Setup Deal failed Commission Mismath: ${commission_validate} != ${agent_commission}  `);
            return
          }
        }


        let data = {
          basic: {
            order_deal_id   : this.deal.order_deal_no,
            price           : this.deal.selling_price,
            contract_date   : this.deal.contract_date,
            acceptance_date: this.deal.acceptance_date,
            completion_date: this.deal.completion_date,
            adjustment_date: this.deal.adjustment_date,
            possession_date: this.deal.possession_date,
            subject_remove_date: this.deal.subject_remove_date,
            is_subject_deal    : this.deal.no_subject ? 0 : 1,
            deal_type          : this.deal.deal_type,
            listing_commission : this.deal.listing_commission,
            selling_commission : this.deal.selling_commission,
            listing_commission_gst : this.deal.listing_commission_gst,
            selling_commission_gst : this.deal.selling_commission_gst,
            mls                    : this.deal.mls,
            deal_business          : this.deal.deal_business
          },

          deposits          : [{ deposit: this.deal.deposit, due_date: this.deal.deposit_due_date, trust: this.deal.deposit_trust_by }],
          properties        : this.deal.properties,
          agents            : this.deal.agents,
          buyer_seller_list : this.deal.buyers_sellers,
          lawyer_list       : this.deal.lawyers,
          other_broker_list : this.deal.other_brokers
        }
        getDealBasicApi().create_new_deal(data).then(res => {
          if (res.errCode == 0) {
            this.$alertify.message("Deal is created");
            this.$router.push({ name: 'deal-pending-list' })
          } else {
            this.$alertify.error("Setup Deal failed:" + res.errCode);
          }
        })
      }

    },


    ///////search lawyer

    queryLawyer(str) {
      search_lawyer({ str: str }).then(res => {
        if (res.errCode == 0) {
          this.lawyer_data = []
          res.data.map(e => this.lawyer_data.push(e))
          if (this.lawyer_data.length <= 0) {
            this.lawyer_data.push({
              name    : str,
              address : '',
              addr    : '',
              phone1  : '',
              email   : '',
              city_name : '',
              postal    : ''

            })
          }
        }
      })
    },

    onLawyerSelected(evt, lawyer, idx) {

      lawyer.name  = evt.name
      lawyer.phone = evt.phone1
      lawyer.email = evt.email
      lawyer.address = evt.addr + ' ' + evt.city_name + ' ' + evt.postal
      lawyer.postal_code = evt.postal
      this.$refs['lawyer_address_'+idx][0].setValue(lawyer.address)
      this.$refs['lawyer_name_'+idx][0].inputValue = evt.name
      
    },

    onLawyerAddreessSelected(addr, evt) {
      let idx = evt.$attrs['lawyer_index']
      let lawyer = this.deal.lawyers[idx]
      lawyer.address = addr.full
    },


    //////////////Buyer/Seller

    onAddNewBuyerSeller() {
      this.deal.buyers_sellers.push({
        type: 'Buyer', 
        email : '',
        email2: ''
      })


      console.log(this.$v.deal.buyers_sellers)

      this.$nextTick(() => {
        let els = document.getElementsByClassName('buyer_seller_choice')
        new Choices(els[els.length - 1], {
          removeItemButton: false,
          itemSelectText  : '',
        });
      });
    },

    onBuyerSellerAddressSelected(addr, evt_cmp) {

      let item = this.deal.buyers_sellers[evt_cmp.$attrs['idx']]
      item.address = addr.full
      item.city = addr.city
    },

    onRemoveBuyerSeller(idx) {
      this.deal.buyers_sellers.splice(idx, 1)
    },


    //////////////Property
    onAddressSelected(addr, evt_cmp) {
    
      let item           = this.deal.properties[evt_cmp.$attrs['idx']]
      item.address       = addr.full
      item.city_name     = addr.city
      item.province_name = addr.province
      item.street        = addr.street
     


      if (addr.reference) {
        postal_code_query({str : addr.reference}).then(res => {
          if (res.errCode == 0) {
            item.postal_code = res.data
            item.address    += item.postal_code
          }
        })
      }
    },


    onAddProperty() {
      let obj = {
        unit          : '',
        pid           : '',
        address       : '',
        city_name     : '',
        province_name : '',
        postal_code   : '',
        street        : ''
      }

      this.deal.properties.push(obj)
    },

    onRemoveProperty(idx) {
      this.deal.properties.splice(idx, 1)
    },



    //////////////Agent
    onSelectedAgent(selected_agent) {
      
      console.log(selected_agent)


      if ( selected_agent.user_obj.agent_type == 'broker') {
        let idx  = selected_agent.user_obj.broker_index

        let broker = this.deal.other_brokers[idx]

        broker.brokerage    = selected_agent.agent.brokerage_name
        broker.address      = selected_agent.agent.address
      
        broker.label        = selected_agent.label
        broker.name         = broker.brokerage

        broker.first_name   = selected_agent.agent.first_name
        broker.last_name    = selected_agent.agent.last_name
        broker.phone        = selected_agent.agent.phone
        broker.email        = selected_agent.agent.email
        
        broker.agent_vin    = selected_agent.agent.vin
        broker.office_code  = selected_agent.agent.office_code

        return
      }


      let agent = selected_agent.user_obj


      
      if (agent.local_checked) {
        agent.vin          = selected_agent.agent.code
        agent.brokerage    = ''
      } else {
        agent.vin          = selected_agent.agent.vin
        agent.brokerage    = selected_agent.agent.brokerage_name
        agent.address      = selected_agent.agent.address
      }
      agent.label        = selected_agent.label
      agent.name         = selected_agent.agent.first_name + ' ' + selected_agent.agent.last_name

      agent.first_name   = selected_agent.agent.first_name
      agent.last_name    = selected_agent.agent.last_name
      agent.phone        = selected_agent.agent.phone
      agent.email        = selected_agent.agent.email
      
      agent.agent_vin    = agent.vin
      agent.office_code  = selected_agent.agent.office_code

      if (agent.local_checked) {
        agent.agent_id     = selected_agent.agent.id
      } else {
        delete  agent.agent_id
      }

    },

    onAddNewAgent() {
      
      this.local_obj_id ++
      let obj = {
        vin: '',
        name: '',
        agent_type: (this.deal.deal_type == 'L' ? '1': '2'),
        commission: 0,
        gst : 0,
        total :0,
        commission_required : true,

        commission_2 : 0,
        gst_2 : 0,
        choice: {},
        search_type : 'LOCAL',
        local_checked : true,
        temp_obj_id   : this.local_obj_id
      }

      this.deal.agents.push(obj)
    
    },

    removeAgentItem(idx) {
      this.deal.agents.splice(idx, 1)
      this.update_total_commission()
    },


    
    onAddNewLawyer() {
      
      this.deal.lawyers.push({ type  :'Buyer Lawyer'})

      this.$nextTick(() => {
        let els = document.getElementsByClassName('lawyer_choice')
        new Choices(els[els.length - 1], {
            removeItemButton: false,
            itemSelectText  : '',
        });
      })
        

    
    },

    onRemoveLawyer(idx) {
      this.deal.lawyers.splice(idx, 1)
    },


    onAddOtherBroker() {
      this.deal.other_brokers.push({ agent_name_required: false, brokerage : '' , email :'', phone : '', name : '', commission: 0, gst : 0, total : 0, type  :this.deal.deal_type == 'L'  ?'Selling Broker' :'Listing Broker', temp_obj_id   : this.local_obj_id++})
    },

    onRemoveOtherBroker(idx) {
      this.deal.other_brokers.splice(idx, 1)
    },

    onDealBusinessChange() {
    
      this.deal.agents = []
      if (this.deal.deal_business == 'P' || this.deal.deal_business =='H') {
        this.deal.agents = [{agent_type : 1, local_checked: true, temp_obj_id   : this.local_obj_id}]
      } 
      this.local_obj_id ++

    },

    onSideChange() {
      this.deal.agents = []
      this.update_total_commission()
    },


   

    validate_gst_required() {
      this.gst_required = false
      this.deal.agents.map(e =>{
        if (!e.gst) {
          e.commission_gst_required = true
        } else {
          e.commission_gst_required = false
          e.total = e.commission  + e.gst

         
          this.$refs['agent_comm_total_'+e.temp_obj_id][0].setValue(e.total)
          if ( this.$refs['agent_comm_total_'+e.temp_obj_id][1]) {
            this.$refs['agent_comm_total_'+e.temp_obj_id][1].setValue(e.total)
          }
        }
      })
    },

    validate_comm_required() {
      
      
      this.deal.agents.map(e =>{
        if (!e.commission) {
          e.commission_required = true
        } else {

          e.commission_required = false
          e.gst   = Number(e.commission) * 0.05
          e.total = e.commission  + e.gst
         
        }
       
        if (!e.gst) {
          e.commission_gst_required = true
        } else {
          e.commission_gst_required = false
        }
       
        this.$refs['agent_comm_gst_'+e.temp_obj_id][0].setValue(e.gst)
        this.$refs['agent_comm_total_'+e.temp_obj_id][0].setValue(e.total)

      
        
      })


     
      this.update_total_commission()

    },

    other_comm_watcher() {

      
      this.deal.other_brokers.map(e =>{
        if (e.commission) {
          e.gst  = financial(Number(e.commission) * 0.05, 2)
        } else {
          e.gst  = 0
        }

        e.total  = financial(Number(e.commission)  + e.gst, 2)

        this.$refs['agent_broker_comm_gst_'+e.temp_obj_id][0].setValue(e.gst)
        this.$refs['agent_broker_comm_total_'+e.temp_obj_id][0].setValue(e.total)

      })

      this.update_total_commission()

      
    },

    other_comm_gst_watcher() {

      
      this.deal.other_brokers.map(e =>{

        e.total  = financial(Number(e.commission)  + Number(e.gst), 2)
        this.$refs['agent_broker_comm_total_'+e.temp_obj_id][0].setValue(e.total)
      })


    },

    update_total_commission() {

      this.deal.listing_commission       = 0
      this.deal.listing_commission_gst   = 0
      this.deal.listing_commission_total = 0
      this.deal.selling_commission       = 0
      this.deal.selling_commission_gst   = 0
      this.deal.selling_commission_total = 0
  
      
      if (this.deal.deal_type == 'L') {
          this.deal.listing_commission         = financial(this.deal.agents.reduce((a, c) => a += Number(c.commission), 0), 2)
          this.deal.listing_commission_gst     = financial(this.deal.agents.reduce((a, c) => a += Number(c.gst), 0), 2)
          this.deal.listing_commission_total   = financial(this.deal.listing_commission + this.deal.listing_commission_gst, 2)


          this.deal.selling_commission         =  financial(this.deal.other_brokers.reduce((a, c) => a += Number(c.commission), 0), 2)
          this.deal.selling_commission_gst     =  financial(this.deal.other_brokers.reduce((a, c) => a += Number(c.gst), 0), 2)
          this.deal.selling_commission_total   =  financial(this.deal.selling_commission + this.deal.selling_commission_gst, 2)

      } else if (this.deal.deal_type == 'S') {
          this.deal.selling_commission         = financial(this.deal.agents.reduce((a, c) => a += Number(c.commission), 0), 2)
          this.deal.selling_commission_gst     = financial(this.deal.agents.reduce((a, c) => a += Number(c.gst), 0), 2)
          this.deal.selling_commission_total   = financial(this.deal.selling_commission + this.deal.selling_commission_gst, 2)


          this.deal.listing_commission         = financial(this.deal.other_brokers.reduce((a, c) => a += Number(c.commission), 0), 2)
          this.deal.listing_commission_gst     = financial(this.deal.other_brokers.reduce((a, c) => a += Number(c.gst), 0), 2)
          this.deal.listing_commission_total   = financial(this.deal.listing_commission + this.deal.listing_commission_gst, 2)
      }

      
      this.$refs['listing_commission_ref'].setValue(this.deal.listing_commission )
      this.$refs['listing_commission_gst_ref'].setValue(this.deal.listing_commission_gst )
      this.$refs['listing_commission_total_ref'].setValue(this.deal.listing_commission_total )
      this.$refs['selling_commission_ref'].setValue(this.deal.selling_commission )
      this.$refs['selling_commission_gst_ref'].setValue(this.deal.selling_commission_gst )
      this.$refs['selling_commission_total_ref'].setValue(this.deal.selling_commission_total )
    },


    getSide() {
      if (this.deal.deal_type == 'L') {
        return 'LISTING'
      } else if  (this.deal.deal_type == 'S') {
        return 'SELLING'
      } else {
        return ''
      }
    },

    getOppSide() {
      if (this.deal.deal_type == 'L') {
        return 'SELLING'
      } else if  (this.deal.deal_type == 'S') {
        return 'LISTING'
      } else {
        return ''
      }
    },





  },

  data() {
    return {
      title: "Deal",
      items: [
        {
          text: "Deal List",
          href: "/deal/pending_deal",
        },
        {
          text: "Setup New Deal",
          active: true,
        },
      ],
      gst_required               : false,
      other_broker_name_required : false,
      
     

      deal: {
        deal_business    : 'R',
        mls              : '',
        deal_type        : 'L',
        deposit          : 0,
        deposit_due_date : '',
        deposit_trust_by : '0',
        order_deal_no    : '',
        agents           : [],
        properties       : [],
        buyers_sellers   : [],
        lawyers          : [],
        other_brokers    : [],
      },

      
      searched_property_address: '',
      searched_lawyer: '',
      buery_seller_address: '',
      address_data: [],
      deal_type_list: [],
      lawyer_data: [],
      lawyer_choices: [],

      agent_commission_amount : '',
      local_obj_id : 1000,

    };
  },
  components: {
    Layout,
    PageHeader,
    flatPickr,
    AgentAutoComplete,
    AddressAutoComplete,
    VueBootstrapTypeahead,
    CurrencyInput,
   // CurrencyInput

  },

  created() {
    
  },

  mounted() {

    getDealBasicApi().get_new_deal_no().then(res => {
      if (res.errCode == 0) {
        this.deal.order_deal_no = res.data

      }
    })

  
    new Choices('#deal_type')

    new Choices('#deposit_trust_by')

    new Choices('#deal_business')


  },

  watch: {


    searched_lawyer(new_search_name) {
      this.queryLawyer(new_search_name)
    },



  },

  computed: {

    tab_resident_type() {
      return this.deal.deal_business != 'H' && this.deal.deal_business != 'P'
    },
   
    tab_referral_type() {
      return this.deal.deal_business == 'H'
    },

    tab_replacement_fee() {
      return this.deal.deal_business == 'P'
    },



  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">

      <div class="col-xxl-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-4">New Deal Info</h5>
            <form class="needs-validation" @submit.prevent="formSubmit">

              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Deal Type</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <div class="row gap-4">
                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label" >Deal No.</label>
                          <input type="text" :value="deal.order_deal_no" class="form-control" readonly />
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Business Type</label>
                          <select id="deal_business" v-model="deal.deal_business" class="form-control" @change="onDealBusinessChange">
                            <option value="R">RESIDENTIAL</option>
                            <option value="C">COMMERICIAL</option>
                            <option value="P">PROPERTY MANAGEMENT</option>
                            <option value="M">PRE SALE</option>
                            <option value="H">REFERRAL</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-lg-2" :class="(deal.deal_business == 'P' || deal.deal_business == 'H') ? 'd-none' :''">
                        <div class="mb-3">
                          <label class="form-label">Side</label>
                          <select id="deal_type" v-model="deal.deal_type" class="form-control" @change="onSideChange">
                            <option value="L">LISTING</option>
                            <option value="S">SELLING</option>
                          </select>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <!-- end card -->

              <!--  tab referral fee /replacement -->
              <div class="row" v-if="tab_referral_type || tab_replacement_fee" :class="tab_referral_type || tab_replacement_fee?'':'d-none'">
                <!-- Agent and Commission -->
                <div class="card border shadow-none mb-5">
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          01
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title">  Agent & Commission</h5>
                    </div>
                  </div>
                  <div class="card-body">

                    <div class="row" v-for="(agent, idx) in deal.agents" :key="'agent_' + idx + '_refer_'">
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label" >Agent</label>
                          <AgentAutoComplete :agent_idx="idx" :init_data="{user_obj : agent, agent_name : agent.label}" @onSelected="onSelectedAgent"  autocomplete="off" />
                        </div>
                      </div>
                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label class="form-label" >Commission</label>
                          <CurrencyInput v-model.lazy="agent.commission"  :class="{ 'is-invalid': agent.commission_required }" @input="validate_comm_required" @blur="validate_comm_required" />
                          <div v-if="agent.commission_required" class="invalid-feedback">
                            <span v-if="agent.commission_required">This value is required.</span>
                          </div>  

                        </div>
                      </div>
                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label class="form-label" >Gst</label>
                          <CurrencyInput v-model="agent.gst" :ref="'agent_comm_gst_'+agent.temp_obj_id" :class="{ 'is-invalid': gst_required }" @input="validate_gst_required" @blur="validate_gst_required" />
                          <div v-if="gst_required" class="invalid-feedback">
                            <span v-if="gst_required">This value is required.</span>
                          </div> 

                        </div>
                      </div>

                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label" >Total</label>
                          <CurrencyInput v-model="agent.gst" :ref="'agent_comm_total_'+agent.temp_obj_id"  readonly />
                         
                        </div>
                      </div>

                     


                    </div>

                  </div>
                </div>
                <!-- end card -->
              </div>

              <!--  tab resident /commercial -->
              <div class="row" v-if="tab_resident_type" :class="tab_resident_type?'':'d-none'">
                <!-- Resident or commercial tab -->
                <div class="card border shadow-none mb-5">
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          01 
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title">General Info</h5>
                    </div>
                  </div>
                  <div class="card-body">
                    <div>
                      <div class="row gap-4">

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Price</label>
                            <CurrencyInput v-model.lazy="deal.selling_price" :class="{ 'is-invalid': $v.deal.selling_price.$error }" />
                            <div v-if="$v.deal.selling_price.$error" class="invalid-feedback">
                              <span v-if="!$v.deal.selling_price.required">This value is required.</span>
                            </div>

                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Deposit</label>
                            <CurrencyInput v-model.lazy="deal.deposit" />
                            <!-- input type="text" v-model="deal.deposit" class="form-control" placeholder="Enter Amount"
                              /-->
                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Deposit Date</label>
                            <flat-pickr v-model="deal.deposit_due_date" placeholder="Select a date" class="form-control"
                              :class="{ 'is-invalid': $v.deal.deposit_due_date.$error }"></flat-pickr>
                            <div v-if="$v.deal.deposit_due_date.$error" class="invalid-feedback">
                              <span v-if="!$v.deal.deposit_due_date.required">This value is required.</span>
                            </div>

                          </div>
                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Trust By</label>
                            <select id="deposit_trust_by" v-model="deal.deposit_trust_by" class="form-control">
                              <option value="1">Us</option>
                              <option value="0">Other</option>
                            </select>
                          </div>
                        </div>
                      </div>


                      <div class="row gap-4 mt-4">
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Contract Date</label>
                            <flat-pickr v-model="deal.contract_date" placeholder="Select a date" class="form-control"
                              :class="{ 'is-invalid': $v.deal.contract_date.$error }"></flat-pickr>
                            <div v-if="$v.deal.contract_date.$error" class="invalid-feedback">
                              <span v-if="!$v.deal.contract_date.required">This value is required.</span>
                            </div>

                          </div>
                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" for="gen-info-designation-input">Acceptance Date</label>
                            <flat-pickr v-model="deal.acceptance_date" placeholder="Select a date" class="form-control"
                              :class="{ 'is-invalid': $v.deal.acceptance_date.$error }"></flat-pickr>
                            <div v-if="$v.deal.acceptance_date.$error" class="invalid-feedback">
                              <span v-if="!$v.deal.acceptance_date.required">This value is required.</span>
                            </div>
                          </div>
                        </div>
                       
                        <div class="col-lg-2">
                          <div class="form-check mt-4">
                            <input type="checkbox" class="form-check-input" id="formrow-customCheck"
                              v-model="deal.no_subject" />
                            <label class="form-check-label" for="formrow-customCheck">No Subject</label>
                          </div>
                        </div>

                        <div class="col-lg-2" :class="deal.no_subject?'d-none':'block'">
                          <div class="mb-3">
                            <label class="form-label" for="gen-info-designation-input">Subject Remove Date</label>
                            <flat-pickr v-model="deal.subject_remove_date" placeholder="Select a date"
                              class="form-control" :disabled="deal.no_subject ? true : false"></flat-pickr>
                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Completion Date</label>
                            <flat-pickr v-model="deal.completion_date" placeholder="Select a date" class="form-control"
                              :class="{ 'is-invalid': $v.deal.acceptance_date.$error }"></flat-pickr>
                            <div v-if="$v.deal.acceptance_date.$error" class="invalid-feedback">
                              <span v-if="!$v.deal.acceptance_date.required">This value is required.</span>
                            </div>

                          </div>
                        </div>


                      </div>
                      <!-- end row-->

                    </div>
                  </div>
                </div>
                <!-- end card -->


                  <!-- Buyer & Seller  -->
                  <div class="card border shadow-none mb-5">
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          02
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title">Buyer & Seller</h5>
                    </div>
                    <div class="flex-grow-1 text-end align-items-right">
                      <b-button variant="primary" @click="onAddNewBuyerSeller" class="col-3">Add Buyer/Seller</b-button>
                    </div>
                  </div>
                  <div class="card-body">

                    <div class="row mt-3" v-for="(bs, idx) in deal.buyers_sellers" :key="'buyer_seller_' + idx">
                      <div class="row">
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">{{ bs.type }} {{ idx + 1 }}</label>
                            <select class="form-control buyer_seller_choice" v-model="bs.type">
                              <option value="Buyer">Buyer</option>
                              <option value="Seller">Seller</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">First Name</label>
                            <input type="text" class="form-control" v-model="bs.first_name" maxlength="50"/>
                          </div>
                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Last Name</label>
                            <input type="text" class="form-control" v-model="bs.last_name" maxlength="50"/>
                          </div>
                        </div>
                      
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Phone</label>
                            <input type="text" class="form-control"  v-mask="'(###) ###-####'" v-model="bs.phone" maxlength="30"/>
                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Email</label>
                            <input  class="form-control" v-model="bs.email" maxlength="80" type="email"  :class="{  'is-invalid': $v.deal.buyers_sellers.$each[idx].email.$error , }"/>
                            <div v-if="$v.deal.buyers_sellers.$each[idx].email.$error" class="invalid-feedback">
                              <span v-if="$v.deal.buyers_sellers.$each[idx].email.$error">This value should be a valid email.</span>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-1">
                          <div class="row icon-demo-content-10 mb-3 d-flex align-items-start justify-content-md">
                            <label class="form-label" > {{ ' ' }}&nbsp;</label>
                            <div class="col-xl-3 col-lg-4 col-sm-6" @click="onRemoveBuyerSeller(idx)">
                              <i class="uil-times font-size-6"></i>
                            </div>
                          </div>
                        </div>

                      </div>
                      
                      <div class="row">
                        <div class="col-lg-1">
                          <div class="mb-3">
                            <label>Unit</label>
                            <input type="text" class="form-control" v-model="bs.unit" />
                          </div>
                        </div>
                        <div class="col-lg-10">
                          <div class="mb-3">
                            <label class="form-label" >Address</label>
                            <AddressAutoComplete :idx="idx" v-model="buery_seller_address"
                              @onSelected="onBuyerSellerAddressSelected" autocomplete="off" />
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <hr class="my-1" />
                      </div>

                    </div>

                  </div>
                </div>
                <!-- end card -->


                <!-- property address -->
                <div class="card border shadow-none mb-5">
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          03
                        </div>
                      </div>

                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title">Property Address</h5>
                    </div>
                    <div class="flex-grow-1 text-end align-items-right">
                      <b-button variant="primary" @click="onAddProperty" class="col-3">Add Address</b-button>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label>MLS</label>
                          <input type="text" class="form-control" v-model="deal.mls" />
                        </div>
                      </div>
                    </div>

                    <div class="row" v-for="(property, idx) in deal.properties" :key="'property_addr_' + idx">
                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label>PID</label>
                          <input type="text"  class="form-control" v-mask="'###-###-###'"  v-model="property.pid" />
                        </div>
                      </div>
                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label>Unit</label>
                          <input type="text" class="form-control" v-model="property.unit" />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mb-3">
                          <label for="workexperience-companyname-input">Property Address</label>
                          <AddressAutoComplete :idx="idx" v-model="searched_property_address"
                            @onSelected="onAddressSelected" autocomplete="off" />
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <div class="row icon-demo-content-10 mb-3">
                          <label class="form-label" > {{ ' ' }}&nbsp;</label>
                          <div class="col-xl-3 col-lg-4 col-sm-6" @click="onRemoveProperty(idx)">
                            <i class="uil-times font-size-4"></i>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <!-- end card -->


            
                 <!-- Other Broker -->
                 <div class="card border shadow-none mb-5" >
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          04A
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title">{{getOppSide() }} Agent & Broker</h5>
                    </div>
                    <div class="flex-grow-1 text-end align-items-right">
                      <b-button variant="primary" @click="onAddOtherBroker" class="col-3">Add Entry</b-button>
                    </div>
                  </div>
                  <div class="card-body">

                    <div class="row" v-for="(broker, idx) in deal.other_brokers" :key="'agent_' + idx + '_other_broker_' + broker.temp_obj_id">
                     
                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label" >Agent</label>
                          <AgentAutoComplete :broker_idx="idx" v-model="broker.agent_name" :init_data="{user_obj: {broker_index : idx, agent_type: 'broker'}, style_class: { 'is-invalid': broker.agent_name_required } }" :show_brokerage="false" :search_type="'REGBV'" @onSelected="onSelectedAgent"
                            autocomplete="off" :class="{ 'is-invalid': true }" />
                            <div v-if="broker.agent_name_required" class="invalid-feedback">
                              <span v-if="broker.agent_name_required">This value is required.</span>
                            </div>
                
                        </div>
                      </div>
                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label">Broker</label>
                          <input type="text" class="form-control" v-model="broker.brokerage" maxlength="80" />
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label" >Commission</label>
                          <CurrencyInput v-model.lazy="broker.commission"   @input="other_comm_watcher" @blur="other_comm_watcher" />
                          
                        </div>
                      </div>
                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label class="form-label" >Gst</label>
                          <CurrencyInput v-model.lazy="broker.gst"  :ref="'agent_broker_comm_gst_'+broker.temp_obj_id"  @input="other_comm_gst_watcher" @blur="other_comm_gst_watcher"  />
                          
                        </div>
                      </div>
                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label class="form-label" >Total</label>
                          <CurrencyInput v-model.lazy="broker.total"  :ref="'agent_broker_comm_total_'+broker.temp_obj_id"   readonly  />
                           
                        </div>
                      </div>

                   
                      <div class="col-lg-1">
                        <div class="row icon-demo-content-10 mb-3">
                          <label class="form-label" > {{ ' ' }}&nbsp;</label>
                          <div class="col-xl-3 col-lg-4 col-sm-6" @click="onRemoveOtherBroker(idx)">
                            <i class="uil-times font-size-4"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <!-- end card Agent & Commission-->

                <!-- Agent -->
                <div class="card border shadow-none mb-5">
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          04B
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title">{{ getSide() }} Agent & Commission</h5>
                    </div>
                    <div class="flex-grow-1 text-end align-items-right">
                      <b-button variant="primary" @click="onAddNewAgent" class="col-3">Add Entry</b-button>
                    </div>
                  </div>
                  <div class="card-body">

                    <div class="row" v-for="(agent, idx) in deal.agents" :key="'agent_' + idx + '_agent_' + agent.vin">
                     
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label" >Agent</label>
                          <AgentAutoComplete :agent_idx="idx" :init_data="{ user_obj : agent, agent_name : agent.label}" :search_type="agent.search_type" @onSelected="onSelectedAgent"
                            autocomplete="off" :class="{ 'is-invalid': true }" />
                           

                        </div>
                      </div>
                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label" >Commission<span v-if="deal.deal_type == 'M'">(Phase 1)</span></label>
                          <CurrencyInput v-model.lazy="agent.commission" :class="{ 'is-invalid': agent.commission_required }" @input="validate_comm_required" @blur="validate_comm_required"  />
                          <div v-if="agent.commission_required" class="invalid-feedback">
                            <span v-if="agent.commission_required">This value is required.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label class="form-label" >Gst</label>
                          <CurrencyInput v-model.lazy="agent.gst"  :ref="'agent_comm_gst_'+agent.temp_obj_id"  :class="{ 'is-invalid': agent.commission_gst_required }" @input="validate_gst_required" @blur="validate_gst_required"  />
                            <div v-if="agent.commission_gst_required" class="invalid-feedback">
                            <span v-if="agent.commission_gst_required">This value is required.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label class="form-label" >Total</label>
                          <CurrencyInput v-model.lazy="agent.total"  :ref="'agent_comm_total_'+agent.temp_obj_id"   readonly  />
                           
                        </div>
                      </div>
                     
                      <div class="col-lg-2" v-if="deal.deal_type == 'M'">
                        <div class="mb-3">
                          <label class="form-label" >Commission<span >(Phase 2)</span></label>
                          <input type="text" v-model="agent.commission_2" class="form-control" 
                          :class="{ 'is-invalid': agent.commission_required }"
                            placeholder="Enter Amount"  @input="validate_comm_required" @blur="validate_comm_required" />
                            <div v-if="agent.commission_required" class="invalid-feedback">
                            <span v-if="agent.commission_required">This value is required.</span>
                          </div>
                        </div>
                      </div> 
                      <div class="col-lg-1"  v-if="deal.deal_type == 'M'">
                        <div class="mb-3">
                          <label class="form-label" >Gst</label>
                          <input type="text" v-model="agent.gst_2" class="form-control"
                          :class="{ 'is-invalid': agent.commission_gst_required }"
                            placeholder="Gst" @input="validate_gst_required" @blur="validate_gst_required" />
                          <div v-if="agent.commission_gst_required" class="invalid-feedback">
                            <span v-if="agent.commission_gst_required">This value is required.</span>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label class="form-label" >Local Agent</label>
                          <input type="checkbox" class="form-check ml-4" @change="agent.search_type = ($event.target.checked?'LOCAL': 'REGBV');" v-model="agent.local_checked" :checked="agent.local_checked" />
                        </div>
                      </div>

                      <div class="col-lg-1">
                        <div class="row icon-demo-content-10 mb-3">
                          <label class="form-label" > {{ ' ' }}&nbsp;</label>
                          <div class="col-xl-3 col-lg-4 col-sm-6" @click="removeAgentItem(idx)">
                            <i class="uil-times font-size-4"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <!-- end card Agent & Commission-->


              

                <!--  Commission Total-->
                <div class="card border shadow-none mb-5">
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          04C
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title">Commission</h5>
                    </div>
                  </div>
                  <div class="card-body">
                    <div>
                      <div class="row gap-4">

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Listing Commission</label>
                            <CurrencyInput ref="listing_commission_ref"   readonly />
                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Listing Commission Gst</label>
                            <CurrencyInput  ref="listing_commission_gst_ref" readonly  />
                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Total Listing Commission</label>
                            <CurrencyInput   ref="listing_commission_total_ref" readonly/>
                          </div>
                        </div>

                      </div>
                      <div class="row gap-4">
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Selling Commisison</label>
                            <CurrencyInput ref="selling_commission_ref"   readonly />
                           
                          </div>

                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Selling Commisison Gst</label>
                            <CurrencyInput ref="selling_commission_gst_ref"  readonly  />
                           
                          </div>

                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Total Selling Commisison</label>
                            <CurrencyInput  ref="selling_commission_total_ref" readonly/>
                          </div>

                        </div>

                        

                      </div>
                    </div>
                  </div>
                </div>
                <!-- end card Commission -->



                <!-- Lawyer  -->
                <div class="card border shadow-none mb-5">
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          06
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title">Lawyer</h5>
                    </div>
                    <div class="flex-grow-1 text-end align-items-right">
                      <div class="flex-grow-1 text-end align-items-right">
                      <b-button variant="primary" @click="onAddNewLawyer" class="col-3">Add Lawyer</b-button>
                    </div>
                    </div>
                  </div>
                  <div class="card-body">

                    <div class="row" v-for="(lawyer, idx) in deal.lawyers" :key="'lawyer_'+idx+'_'+lawyer.id">
                      <div class="row">
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Lawyer {{ idx +1 }}</label>
                            <select class="form-control lawyer_choice" v-model="lawyer.type">
                              <option value="Buyer Lawyer" selected>Buyer Lawyer</option>
                              <option value="Seller Lawyer" >Seller Lawyer</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="mb-3">
                            <label class="form-label">Name</label>
                            <vue-bootstrap-typeahead v-model="searched_lawyer" :remoteMatch="true"
                              :data="lawyer_data" :serializer="s => s.name" :foramterDisplay="s => s.name" :ref="'lawyer_name_'+idx"
                              @hit="onLawyerSelected($event, lawyer, idx)" autocomplete="off" />


                          </div>
                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Phone</label>
                            <input type="text" class="form-control"  v-mask="'(###) ###-####'" v-model="lawyer.phone" maxlength="30"/>
                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Email</label>
                            <input type="text" class="form-control" v-model="lawyer.email" maxlength="80"/>
                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Email2</label>
                            <input type="text" class="form-control" v-model="lawyer.email2" maxlength="80"/>
                          </div>
                        </div>
                        <div class="col-lg-1">

                          <div class="row icon-demo-content-10 mb-3">
                            <label class="form-label" > {{ ' ' }}&nbsp;</label>
                            <div class="col-xl-3 col-lg-4 col-sm-6" @click="onRemoveLawyer(idx)">
                              <i class="uil-times font-size-4"></i>
                            </div>
                          </div>
                        </div>


                      </div>
                      <div class="row">
                        <div class="col-lg-1">
                          <div class="mb-3">
                            <label>Unit</label>
                            <input type="text" class="form-control" v-model="lawyer.unit" />
                          </div>
                        </div>
                        <div class="col-lg-10">
                          <div class="mb-3">
                            <label class="form-label" >Address</label>
                            <AddressAutoComplete :lawyer_index="idx" v-model="searched_property_address" :ref="'lawyer_address_'+idx"
                              @onSelected="onLawyerAddreessSelected" autocomplete="off" />
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <hr class="my-1" />
                      </div>

                    </div>
                   

                 
                  </div>
                </div>
                <!-- end card -->

              </div>


              <div class="text-end">
                <button type="submit" class="btn btn-primary w-sm" @keyup.enter.prevent=""> Submit Deal </button>
              </div>
            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

